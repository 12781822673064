import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import "../App.css";

/* Css Module */
import styles from "./Intro.module.css";

/* react-whatsapp */
import ReactWhatsapp from "react-whatsapp";

/* react-icons */
import { FaLinkedinIn } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
//import { AiOutlineMail } from 'react-icons/ai'
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { SiSahibinden } from "react-icons/si";
import { GrMenu } from "react-icons/gr";

function OffcanvasExample() {
  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          bg="transparent"
          expand={expand}
          className="navbar"
        >
          <Container className="container" fluid>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="btn"
            >
              <GrMenu style={{ width: "2.5rem", height: "2.5rem" }} />
              MENU
            </Navbar.Toggle>
            <Navbar.Offcanvas
              className="body"
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header
                closeButton
                className="close"
              ></Offcanvas.Header>
              <Offcanvas.Body className="menu">
                <Nav className="flex-grow-1 pe-2">
                  <Nav.Link href="https://www.linkedin.com/in/remaxvolkanulusoy/' target='_blank' rel='noopener noreferrer' ">
                    <FaLinkedinIn
                      className={styles.i_social_icon}
                      style={{ color: "#0072b1" }}
                    />
                  </Nav.Link>
                  <Nav.Link
                    href="https://www.facebook.com/profile.php?id=100084326547434"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook
                      className={styles.i_social_icon}
                      style={{ color: "#4267B2" }}
                    />
                  </Nav.Link>
                  <Nav.Link
                    href="https://www.instagram.com/volkanulusoyremax"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsInstagram
                      className={styles.i_social_icon}
                      style={{ color: "#E4405F" }}
                    />
                  </Nav.Link>
                  <Nav.Link
                    href="https://www.youtube.com/channel/UCnp07GIQDa5AMfRRPhkbYfg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube
                      className={styles.i_social_icon}
                      style={{ color: "#c4302b" }}
                    />
                  </Nav.Link>
                  {/* <a href="#contact"  rel="noopener noreferrer">
                    <AiOutlineMail className={styles.i_social_icon}  />
                  </a> */}
                  <Nav.Link
                    href="https://final1.sahibinden.com/?userId=aieNfPk9_a9FN7wpCsxgxJQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SiSahibinden
                      className={styles.i_social_icon}
                      style={{ backgroundColor: "#000", color: "#ffff00" }}
                    />
                  </Nav.Link>
                  <Nav.Link href="">
                    <div>
                      <ReactWhatsapp
                        style={{
                          backgroundColor: "#fff",
                          color: "#4FCE5D !important",
                        }}
                        ReactWhatsapp
                        number="+905322506614"
                        message="Merhaba :)"
                      >
                        <BsWhatsapp
                          className={styles.i_social_icon}
                          style={{
                            color: "#4FCE5D",
                            position: "absolute",
                            left: "18px",
                          }}
                        />
                      </ReactWhatsapp>
                    </div>
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;
