import React from 'react'

/* css */
import styles from './Gallery.module.css'

/* images */
import remaxFinal from '../img/remax-final.png'
import volkanUlusoyBeyl from '../img/eksper.png'
import volkanUlusoyFinal from '../img/remax-fina2l.png'
import volkanUlusoyRemax from '../img/remax-final-ofis.png'

const Gallery = () => {
  return (
    <div className={styles.pl}>
      <div className={styles.pl_texts}>
        <h1 className={styles.pl_title}> <span style={{color: '#dc1c2e', fontWeight: 'bold'}}>RE<span style={{color: '#012158', fontWeight: 'bold'}}>/</span>MAX</span>  <span style={{color: '#012158', fontWeight: 'bold'}}>FİNAL</span>   BEYLİKDÜZÜ</h1>
        <h2 className={styles.pl_desc}>
            "<span style={{color: '#012158', fontWeight: 'normal'}}>ÇÖZÜM</span>    <span style={{color: '#012158', fontWeight: 'bold'}}>  ORTAĞINIZ  </span>"
        </h2>
      </div>
      
      <div className={styles.pl_list}>
        <div className={styles.p}>
        <div className={styles.p_browser}>
            <div className={styles.p_circle}></div>
            <div className={styles.p_circle}></div>
            <div className={styles.p_circle}></div>
        </div>
            <img src={remaxFinal} alt="" className={styles.p_img} />
        </div>

        <div className={styles.p}>
        <div className={styles.p_browser}>
            <div className={styles.p_circle}></div>
            <div className={styles.p_circle}></div>
            <div className={styles.p_circle}></div>
        </div>
            <img src={volkanUlusoyBeyl} alt="" className={styles.p_img} />
        </div>

        <div className={styles.p}>
        <div className={styles.p_browser}>
            <div className={styles.p_circle}></div>
            <div className={styles.p_circle}></div>
            <div className={styles.p_circle}></div>
        </div>
            <img src={volkanUlusoyFinal} alt="" className={styles.p_img_2} />
        </div>

        <div className={styles.p}>
        <div className={styles.p_browser}>
            <div className={styles.p_circle}></div>
            <div className={styles.p_circle}></div>
            <div className={styles.p_circle}></div>
        </div>
            <img src={volkanUlusoyRemax} alt="" className={styles.p_img_2} />
        </div>


      </div>   
    </div>
  )
}

export default Gallery
