import React from "react";

/* css */
import styles from "./Teaser.module.css";

/* react-player */
import ReactPlayer from "react-player/youtube";

const Teaser = () => {
  return (
    <div className={styles.teaser}>
      <div className={styles.player_wrapper}>
        <ReactPlayer
          className={styles.react_player}
          url="https://youtu.be/eai1Bxegswg?si=FBMZgiYeExlZ4zQU"
          width="80%"
          height="80%"
          controls="true"
        />
      </div>
    </div>
  );
};

export default Teaser;
