import React, {useState, useEffect} from 'react'

/* css */
import styles from './About.module.css'

/* images */
import baron2 from '../img/volkan.ulusoy.JPG'
import Email from '../img/email.png'

import axios from "axios";


const About = () => {


  return (
    <div className={styles.a}>
      <div className={styles.a_left}>
        <div className={styles.a_card.bg}></div>
        <div className={styles.a_card}>
            <img src={baron2} alt="" className={styles.a_img} />
        </div>
      </div>
      <div className={styles.a_right}>
            <p className={styles.a_sub}>
            Uluslararası İlişkiler bölümünde lisans öğrenimini tamamladıktan sonra Garanti Bankası’nda iş hayatına başladım.
            </p> 
            <p className={styles.a_desc}>
            Garanti Bankası’nda 14 yıl Portföy Yöneticisi olarak çalıştıktan sonra uzun süredir yatırımcısı olduğum gayrimenkul sektörüne geçme kararı aldım.
            Yatırım, finans ve satış konularındaki bilgi birikimi ve tecrübelerimi ortak hedef ve vizyona sahip olduğum Remax’te sizlerle paylaşıyorum.
            </p>

            <p className={styles.a_desc2}>
            Beylikdüzü ve çevresindeki her türlü ticari ve konut alım, satım ve kiralama işlemlerinizde sizlere hizmet vermek için buradayım.
            </p>
            <div className={styles.a_award}>
            <div className={styles.a_award_texts}>
                
                <p className={styles.a_award_desc}>
                <b>Bir görüşme planlayın</b>  <br /> <br />
                <a href="#contact">
                  <img src={Email} alt="" className={styles.c_icon} />
                </a>
                </p>
            </div>
            </div>
      </div>
    </div>
  )
}

export default About
