import React, { useState, useEffect } from "react"

//import SkewLoader from "react-spinners/SkewLoader"

import { ThreeDots  } from 'react-loader-spinner'

/* import components */

import Intro from "./components/Intro";
import About from "./components/About";
import Teaser from "./components/Teaser";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

/* react-bootstrap */
import 'bootstrap/dist/css/bootstrap.min.css'


function App() {

 const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
   
    <div>
    {loading ?
    
      <ThreeDots 
        height="100"
        width="100"
        radius="9"
        color="#003da5"
        ariaLabel="loading"
        wrapperStyle={{justifyContent: 'center', alignItems: 'center'}}
        wrapperClass
      />
      :
      <>
        
        <Intro />
        <About />
        <Teaser />
        <Gallery />
        <Contact />
        <Footer />
      </>


    }
    </div>
    
  );
}

export default App;
