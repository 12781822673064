import React from 'react'




/* Css Module */
import styles from './Intro.module.css'

/* framer-motion */
import { motion } from "framer-motion"



/* images */
import baloon from '../img/remax-baloon.svg'
import baron from '../img/volkan-ulusoy.png'



import Navbar from "./Navbar"

const Intro = () => {
  

  return (

   <div className={styles.i}>
      <motion.div 
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 3 }}
      className={styles.logo}>
      <img 
         className={styles.logo_img} 
         src={ baloon } 
         alt="remax-final" 
        />
      </motion.div>
      
    <Navbar />
     

        <div className={styles.i_left}>
            <div className={styles.i_left_wrapper}>
                <div className={styles.i_}>
                  <h2 className={styles.i_intro}>Gayrimenkul Danışmanı</h2> 
                  <h1 className={styles.i_name}>Volkan ULUSOY</h1>  
                </div>
                <div className={styles.i_title}>
                    <div className={styles.i_title_wrapper}>
                        <div className={styles.i_title_item}>Ticari & Konut</div>
                        <div className={styles.i_title_item}>Alım & Satım </div>
                        <div className={styles.i_title_item}>& Kiralama</div>
                        <div className={styles.i_title_item}>"Çözüm Ortağınız"</div>
                        <div className={styles.i_title_item}>"Sizin için buradayım.."</div>
                    </div>
                </div>
            </div>
            <svg
          width="75"
          height="75"
          viewBox="0 0 75 75"
          fill="none"
          stroke="red"
          className={styles.i_scroll}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="scroll">
            <path
              id="Vector"
              d="M40.5 15L34.5 9L28.5 15"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_2"
              d="M28.5 24L34.5 30L40.5 24"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g id="Group">
              <path
                id="Vector_3"
                d="M9 37.5H60"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <path
              id="Vector_4"
              d="M34.5 27V9"
              stroke-width="2.9895"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g id="Group_2">
              <path
                id="Vector_5"
                d="M9 27C9 12.918 20.418 1.5 34.5 1.5C48.5859 1.5 60 12.918 60 27C60 29.8906 60 45.1094 60 48C60 62.082 48.5859 73.5 34.5 73.5C20.418 73.5 9 62.082 9 48C9 45.1094 9 29.8906 9 27Z"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </g>
        </svg>
        </div>
        <div className={styles.i_right}>
            <div className={styles.i_bg}></div>
            <img src={baron} alt="volkan-ulusoy" className={styles.i_img} />
        </div>
      </div>
  )
}

export default Intro
