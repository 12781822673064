import React from 'react'

import ScrollToTop from "react-scroll-to-top"

/* css */
import styles from './Footer.module.css'

const Footer = () => {
  return (
    <div className={styles.f_container}>
        <h3>2022 ©️   <a className={styles.f} href="https://www.cemakdeniz.com" target='_blank' rel="noopener noreferrer">Cem Akdeniz</a> </h3>
        <ScrollToTop smooth color='#dc1c2e' className={styles.f_scroll} />
    </div>
  )
}

export default Footer
